import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: '/api',
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }

);
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Remove token and redirect
        if (error.response.status === 401) {
            toast.error('not Authorized');
            localStorage.clear()
            window.location.reload();
        }

        if (error.response.status === 404) {
            toast.error('Not Found');
        }

        if (error.response.status === 500) {
            toast.error('Internal Server Error');
        }

        if (error.response.status === 508) {
            toast.error('Time Out');
        }

        return Promise.reject(error);
    }
);


export default api
