import React, { useMemo ,useState,useEffect } from "react";
// import CardMenu from "components/card/CardMenu";
// import Checkbox from "components/checkbox";
import Card from "components/card";
// import axios from "axios";
// import StatisticsButton from "./StatisticsButton";
import "../../../../../src/assets/css/App.css"
import Dropdown from "components/dropdown";
import { BsThreeDots } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegWindowClose } from "react-icons/fa";
import { RiAddBoxFill } from "react-icons/ri";
import { MdLastPage } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import { message } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import api from "views/auth/Api";
import { jwtDecode } from "jwt-decode";
import Progress from "components/progress";
// import NeedHelp from "../NeedHelp"
import { API_BASE_URL } from '../../../../configs/AppConfig';
// import { Spinner } from "@chakra-ui/spinner"



import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";


// ***************************** GlobalFilter *****************************

// const GlobalFilter = ({filter, setFilter}) => {
//   return (
//       <span className=" h-7">
//           <input placeholder="Search" className=" text-sm	rounded border-2 p-1 h-7" onChange={(e) => setFilter(e.target.value)} />
//       </span>
//   )
// }

  // **********************************************************

const CheckTable = (props) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [id , setId] = useState("");
  const [editData , setEditData] = useState();
  // const [isModalOpen, setIsModalOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);
    const [pageNum , setPageNum] = useState(0);
    const [isLoading , setIsLoading] = useState(false);
    // const [btnSelect , setIBtnSelect] = useState(false);    

    
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const role = decoded.role



// ***************************** Massages *****************************
  const success = () => {
    message.success('done');
  };

  const errorM = () => {
    message.error('This is a message of error');
  };
  
  const delSuccess = ()=>{
    message.success('Delete done');
  }
// **********************************************************
// ***************************** Delete Statistic *****************************

const deleteData = async(id) => {
  setIsOpen(false);
  if (id) {
    setIsLoading(true);
    await api.delete(`${API_BASE_URL}statistics/${id}`)
      .then(function (response) {
          setTlData(response.data);
          setFelterTlData(response.data);
          delSuccess();
          // setIsLoading(false);
      }).catch(error => {
        console.log("handlesubmit error for blog ", error)
        setIsLoading(true);
      });

  }
}
    // **********************************************************

// ***************************** Add Statistic *****************************
    const addOne = ()=>{
      setEditData(null)
      if (!isOpen) {
        setIsOpen(true)
    }
    else{
      setIsOpen(false)
    }
    setDelOpen(false);
    }

  const addStatistic= ()=> {
    return (
      <Dropdown
        button={
          <button
            className={`flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDaddStatistic`}
          >  
            <RiAddBoxFill className="h-6 w-6" />
          </button>
        }
        />)
  }
  // **********************************************************

// ***************************** Edit Statistic *****************************

  const statisticsButton= (id)=>{
    // const { id } = props;
    // const addStatistic = (id) => {
    //   return (
    //     <AddStatistic id={id} />
    //   )
    // }
    const editStatistic= (id)=> {
      if (!isOpen) {
        setIsOpen(true)
    }      
      const ddata = [...tlData]
      const perData = ddata.filter((per) => per.id ===id)
      setEditData(perData[0]);
    }

      // else{
      //   setIsOpen(false)
      // }
      // console.log(isOpen,'isopen')
      // const openLoginPopup = () => setIsOpen(true);
      // const closeLoginPopup = () => setIsOpen(false);
      // if (isOpen) {
          // }

    // **********************************************************

    // ***************************** Dropdown edit and  delete *****************************

    return (
      <Dropdown
        button={
          <button
            onClick={() => {setOpen(!open);
              setDelOpen(false);
              setIsOpen(false);
            } }
            open={open}
            className={` flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
               linear justify-center rounded-lg font-bold transition duration-200 `}
          >
            
            <BsThreeDots className="h-4 w-4" />
          </button>
        }
        animation={"origin-top-right transition-all duration-300 ease-in-out"}
        classNames="top-8 right-0 w-max "
        children={
          <div className= {`z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none`} >
            {/* ${open ? "scale-100" : "hidden"} */}
            <p className=" flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium hoverEdit" onClick={() => editStatistic(id)}>
              <span>
                <MdEdit />
              </span>
              Edit
            </p>
            
             <p className=" mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium hoverEdit" onClick={()=>{setDelOpen(true)}}>
         {/* {onClick={() => deleteData(id)} } */}
              <span>
                <FaRegTrashAlt />
              </span>
              Delete
            </p>           
          </div>
        }
      />
    );
  }
  
  const rowId =(id)=>{
    setId(id)
  }    
  // **********************************************************

  // ***************************** Get all Statistic *****************************
  
  const [tlData , setTlData] = useState([]);
  const [felterData , setFelterTlData] = useState([]);
  // const strData = [...tlData];
  // var n = 1.2450001
  // var noZeroes = strData.toString()
  // console.log(strData[0],"noZeroes")
  
  const getData = async()=>{
    setIsLoading(true);
    await api.get(`${API_BASE_URL}statistics/`)
  .then(function (response) {
    setTlData(response.data)
    // setIsLoading(false);
  }).catch(error => {
    console.log("handlesubmit error for blog get ", error)
    localStorage.clear()
    window.location.reload();
  });
}
  useEffect(()=>{ 
    getData();
  },[]);

  const felData = async()=>{
    await api.get(`${API_BASE_URL}statistics/`)
  .then(function (response) {
    setFelterTlData(response.data)
  }).catch(error => {
    console.log("handlesubmit error for blog get ", error)
    localStorage.clear()
    window.location.reload();
  });
}
  useEffect(()=>{ 
    felData();
  },[]);



  const marketsFilter = [];
  const listMarkert = felterData.map((row) => row.market)
  for (let i = 0; i < listMarkert.length; i++) {
    if(!marketsFilter.includes(listMarkert[i]))
      marketsFilter.push(listMarkert[i]);
  }

  const industryFilter =[];
  const listIndustry = felterData.map((row) => row.industry)
  for (let i = 0; i < listIndustry.length; i++) {
    if(!industryFilter.includes(listIndustry[i]))
      industryFilter.push(listIndustry[i]);
  }
  const { columnsData} = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  // console.log(columns,"columnscolumns")
  const data = useMemo(() => tlData, [tlData]);

  // const initialState = React.useMemo(() => props.initialState);

    // **********************************************************

// ***************************** search filter *****************************


//   const globalFilter = React.useCallback((rows, ids, query) => {
//     const searchTerm = String(query);
//     console.log(searchTerm,"searchTerm")

//     return rows.filter((row) => {
//         const matches = ids.filter(() => {
//             const rowValue = row.values.market ;
//             let searchTermLower = searchTerm.toLowerCase();
//             console.log(row.values.market,"rowValue")

//             if (searchTerm === '"')  {
//                 // ignore first ' " ' handle it as no input
//                 return true
//             }
//             else if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
//                 const exactSearchTerm = searchTerm.slice(1, -1);
//                 return String(rowValue) === exactSearchTerm;
//             }
//             else if (searchTerm.startsWith('"'))  {
//                 // ignore until ending ' " ' has been entered
//                 searchTermLower = searchTerm.slice(1);
//             }

//             return rowValue !== undefined
//                 ? String(rowValue).toLowerCase() === (searchTermLower.toLowerCase())
//                 :  false ;
//         });

//         return matches.length > 0;
//     });
// }, []);

const [marketList , setMarketList] = useState();
const optMarket = ()=> { 
  let oppMarket =[]
  for (let i = 0; i < marketsFilter.length; i++) {
 oppMarket.push(<option value={marketsFilter[i]} >{marketsFilter[i]}</option>)
 }
 return oppMarket
}
const MarketDropFilter = ({setFilter}) => {
  return (
          <select value={marketList} className="filterTableDrop " onChange={(e) => { setFilter(e.target.value) 
            setIsLoading(false)}} >
          <option hidden value="All">Select Market</option>
          {/* <option value= "All"  >All</option> */}
            {optMarket()}
          </select>
  )
}

const [industryList , setIndustryList] = useState();
const optIndustry = ()=> { 
  let oppIndustry =[]
  for (let i = 0; i < industryFilter.length; i++) {
 oppIndustry.push(<option value={industryFilter[i]} >{industryFilter[i]}</option>)
 }
 return oppIndustry
}

const IndustryDropFilter = ({setFilter}) => {
  return (
          <select value={industryList} className="filterTableDrop " onChange={(e) =>  { setFilter(e.target.value)
            setIsLoading(false)}} >
          <option hidden >Select Industry</option>
          {/* <option value= "All" >All</option> */}
            {optIndustry()}
          </select>
  )
}


const tableFilter = async()=>{
//   if(!marketList & !industryList){
//     api.get('http://localhost:3001/statistics')
//     .then(function (response) {
//         setTlData(response.data)
//       }).catch(error => {
//         console.log("handlesubmit error for blog get ", error)
//         localStorage.clear()
//         window.location.reload();
//       });    
//   }  
//   else if(!marketList & industryList){
//     api.get('http://localhost:3001/statistics',{
//       params: {
//         industry : industryList
//       }})
//     .then(function (response) {
//         setTlData(response.data)
//       }).catch(error => {
//         console.log("handlesubmit error for blog get ", error)
//         localStorage.clear()
//         window.location.reload();
//       });    
//   }
//   else if(marketList & !industryList){
//   api.get('http://localhost:3001/statistics',{
//     params: {
//       market: marketList,
//     }})
//   .then(function (response) {
//       setTlData(response.data)
//     }).catch(error => {
//       console.log("handlesubmit error for blog get ", error)
//       localStorage.clear()
//       window.location.reload();
//     });    
// }
  setIsLoading(true);
  await api.get(`${API_BASE_URL}statistics/`,{
    params: {
      market: marketList,
      industry : industryList
    }})
  .then(function (response) {
      setTlData(response.data)
      felData();
      setPageNum(0)
      setIsLoading(false);
    }).catch(error => {
      console.log("handlesubmit error for blog get ", error)
      localStorage.clear()
      window.location.reload();
    });    
}

const resetFilter = async()=>{
    //   api.get(`${API_BASE_URL}statistics/`)
    // .then(function (response) {
    //     setTlData(response.data);
    //   }).catch(error => {
    //     console.log("handlesubmit error for blog get ", error)
    //     localStorage.clear()
    //     window.location.reload();
    //   });   
    setMarketList();
    setIndustryList();
    getData();
    felData();
}

//************************************************************

// ***************************** useTable *****************************

  const tableInstance = useTable(
    {
      columns,
      data,
      // globalFilter,
      initialState: { pageSize: 15, pageIndex:pageNum }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    // setGlobalFilter,
    state: { pageIndex}

  } = tableInstance;
  // initialState.pageSize = 15;
  // initialState.pageIndex=pageNum;                



//************************************************************

// ***************************** return Table *****************************

  return (
    <>
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white w-auto	flex flex-row justify-center items-center">
         <span className="mr-5 MDtitle"> Filter By </span>
          <div className="filterDev">
          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/> */}
          <MarketDropFilter setFilter={setMarketList}/>
          <IndustryDropFilter setFilter={setIndustryList}/>
          <button className="filterBtn " disabled={isLoading} onClick={() => tableFilter()}><span className="text-blue-700">Filter</span></button>
          <button className="filterBtn " disabled={isLoading} onClick={() => resetFilter()}><span className="text-blue-700">Reset</span></button>

          {/* <DynamicDropdown/> */}
          {/* {customFilter()} */}
          </div>
        </div>

        {/* <CardMenu /> */}
       {role === "Admin" ? <div id="addStatistic" onClick={() => addOne()}>{addStatistic()}</div> : null} 
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden pb-[72px] MDchecktable">
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
          // id="checkTableH"
        >
          <thead className="mb-2.5">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: column.def }))}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {tlData[0] ? <tbody {...getTableBodyProps()} id="tbody">
            {page.map((row, index) => {
              prepareRow(row);
      
              return (
                <tr {...row.getRowProps()} key={index} id="trhight" >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Market") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Industry") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } 
                    else if (cell.column.Header === "Platform") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } 
                    else if (cell.column.Header === "Micro-Target" & role==="Admin") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {" "}
                          {cell.value}{" "}
                        </p>
                      );
                    } 
                    else if (cell.column.Header === "CPM") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {Number(cell.value)} $
                        </p>
                      );
                    }
                    else if (cell.column.Header === "LINK-CTR") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {Number(cell.value)} %
                        </p>
                      );
                    }
                    else if (cell.column.Header === "Ad-Angle") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "CTR-All") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {Number(cell.value)} %
                        </p>
                      );
                    }
                    else if (cell.column.Header === "Saturation point") {
                      data = (
                        // <p className="text-sm font-bold text-navy-700 dark:text-white">
                        //   {cell.value} %
                        // </p>
                        <div className="ProgressSat">
                        <Progress width="w-[108px]" value={cell.value} />
                         <p className="ProgressP text-sm font-bold text-navy-700 dark:text-white" >{Number(cell.value)} %</p> 
                         </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[5px] pb-[5px] sm:text-[14px]	"
                      >
                        {data}
                      </td>
                    );
                  })}
                 {role ==="Admin" ? <div id="TR" onClick={()=>{ rowId(row.original.id) 
                 setPageNum(pageIndex)}
                 } >{statisticsButton(id)} </div> : null}
                </tr>
              );
            })}
          </tbody> : <tbody> <tr><td className="w-[5px] h-[5px]"> 
            {isLoading ? <div class="loader"></div> : <h2 className="noDataFound">No Data Available</h2>}
          </td></tr> </tbody>}
          {/* <h2 className="noDataFound">No Data Available</h2> */}
              
        </table>
    {  //************************************************************

// ***************************** Add and Edit modal *****************************
}
        { isOpen ?
        <div class="modal">
        <div id="popup">
          <button id="popIcon" onClick={()=>{setIsOpen(false)}}>
            <FaRegWindowClose className="w-full h-full text-blue-700	"/>
            </button>
                  <Formik
                      initialValues={ !editData?
                        {market: "", industry: '',platform: '', microTarget: '', CPM: "", CTR: '',
                          AdAngle: '', CTRall: '', saturationPoint: ''}
                      :{market: editData.market ? editData.market :"" ,
                       industry: editData.industry ? editData.industry : "",
                       platform: editData.platform ? editData.platform :"" ,
                       microTarget: editData.microTarget ? editData.microTarget: "", 
                       CPM: editData.CPM ? Number(editData.CPM) : "", 
                       CTR: editData.CTR ? Number(editData.CTR) : "",
                       AdAngle: editData.AdAngle ? editData.AdAngle : "", 
                        CTRall: editData.CTRall ? Number(editData.CTRall) : "", 
                        saturationPoint: editData.saturationPoint ? editData.saturationPoint : ""
                    }} 
                      validate={values => {
                          const errors = {};
                          if (!values.market) {
                              errors.market = 'Market is required';
                          }
                          else if (!values.industry) {
                              errors.industry = 'Industry is required';
                          }
                          else if (!values.platform) {
                            errors.platform = 'Platform is required';
                        }
                        else if (!values.microTarget) {
                          errors.microTarget = 'Micro Target is required';
                      }
                      else if (!values.CPM) {
                        errors.CPM = 'CPM is required';
                    }
                    else if (!values.CTR) {
                      errors.CTR = 'CTR is required';
                  }
                  else if (!values.AdAngle) {
                    errors.AdAngle = 'Ad-Angle ROAS is required';
                }
                else if (!values.CTRall) {
                  errors.CTRall = 'CTR all is required';
              }
              else if (!values.saturationPoint) {
                errors.saturationPoint = 'Saturation Point is required';
            }
                 return errors; 
                      }}

                      onSubmit={(values,{setSubmitting}) => {
                        if(!editData){
                          setIsLoading(true);
                           api.post(`${API_BASE_URL}statistics/`, {
                              market: values.market.trim(),
                              industry: values.industry.trim(),
                              platform: values.platform.trim(),
                              microTarget: values.microTarget.trim(),
                              CPM: values.CPM,
                              CTR: values.CTR,
                              AdAngle: values.AdAngle.trim(),
                              CTRall: values.CTRall,
                              saturationPoint: values.saturationPoint,
                          }).then(function (response) {
                            console.log(response,"responseresponse")
                              setIsOpen(false);
                              setTlData(response.data);
                              setFelterTlData(response.data);
                              // setIsLoading(false);
                          }).catch(error => {
                            console.log("handlesubmit error for blog ", error)
                            errorM();
                            setIsLoading(true);
                            setSubmitting(false);
                          });
                        }
                        else if(editData){
                          setIsLoading(true);
                          api.patch(`${API_BASE_URL}statistics/${editData.id}`, {
                              market: values.market.trim(),
                              industry: values.industry.trim(),
                              platform: values.platform.trim(),
                              microTarget: values.microTarget.trim(),
                              CPM: values.CPM,
                              CTR: values.CTR,
                              AdAngle: values.AdAngle.trim(),
                              CTRall: values.CTRall,
                              saturationPoint: values.saturationPoint,
                          }).then(function (response) {
                            setIsOpen(false);
                            setTlData(response.data)
                            setFelterTlData(response.data);
                            success();
                            // setIsLoading(false);
                          }).catch(error => {
                            console.log("handlesubmit error for blogggggggggg ", error)
                            errorM();
                            setSubmitting(false);
                            setIsLoading(true);
                          });
                        }
                        }
                      }
                  >
                      {({ isSubmitting }) => (
                          <Form className={`flex items-center justify-center flex-col w-full`}>
                              <label id="label" className={`flex items-center justify-start flex-row w-full text-sm`} >Market</label>
                              <Field type="text" placeholder="Market" name="market" id="market" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none`} />
                              <ErrorMessage name="market" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Industry</label>
                              <Field type="text" placeholder="Industry" name="industry" id="industry" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="industry" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`} >Platform</label>
                              <Field type="text" placeholder="Platform" name="platform" id="platform" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none`} />
                              <ErrorMessage name="platform" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Micro-Target</label>
                              <Field type="text" placeholder="Micro-Target" name="microTarget" id="microTarget" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="microTarget" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CPM</label>
                              <Field type="number" placeholder="CPM" name="CPM" id="CPM" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CPM" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CTR</label>
                              <Field type="number" placeholder="CTR" name="CTR" id="CTR" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CTR" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Ad-Angle</label>
                              <Field type="text" placeholder="Ad-Angle" name="AdAngle" id="AdAngle" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="AdAngle" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CTR All</label>
                              <Field type="number" placeholder="CTR All" name="CTRall" id="CTRall" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CTRall" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Saturation Point</label>
                              <Field type="number" placeholder="Saturation Point" name="saturationPoint" id="saturationPoint" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="saturationPoint" component="div" id="erroMassage" />
                              
                              <button type="submit" disabled={isSubmitting} className=" flex items-center justify-center linear mt-2 w-40 h-10 rounded-xl bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                  {editData ? "Edit": 'Add'}
                              </button>
                              
                          </Form>
                      )}
                  </Formik>
              </div> </div> : <></>
              }
              { delOpen ? <div className="modal"> <div id="delPopup">
                  <button id="popIcon" onClick={()=>{setDelOpen(false)}}>
            <FaRegWindowClose className="w-full h-full text-blue-700	"/>
            </button>
                <div  id="delText"><span >Are you sure want to delete this data </span></div>
                <div id="yesno">
                <button className="yesorno" onClick={()=>{setDelOpen(false)}}> No </button> 
                <button className="yesorno" onClick={() => {deleteData(id)
                  setDelOpen(false)}
                }> Yes </button>  </div>
              </div></div>: <></>
              }
 </div>
{ //************************************************************
//***************************** table Pagination *****************************
}
     
      <div id="devbody">
           <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}> 
         <MdFirstPage/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => previousPage()} disabled={!canPreviousPage}>
        <MdNavigateBefore/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => nextPage()} disabled={!canNextPage}>
          <MdNavigateNext/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <MdLastPage/>
        </button>
        <span className=" mr-1 ml-2 pt-2 h-full MDpageMag">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span className=" mr-1 leading-loose	h-full MDpageMag">
          | Go to page:
          <input
          className="rounded border-2	ml-2 h-full p-1 MDinpMag "
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>{" "}
        {/* <select className="rounded border-2	ml-2 h-full MDpageSize"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 15].map((pageSize) => (
            <option key={pageSize} value={pageSize} select >
              Show {pageSize}
            </option>
          ))}
        </select> */}
           </div>
    </Card>
    {/* {role==="User" ? <NeedHelp/>: <div></div>} */}
    </>
  );
};

export default CheckTable;
