import { Link } from "react-router-dom";

import "../../../src/assets/css/cards.css"

export default function WelcomeAuth() {

    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Welcome
                </h4>
                <div className="mt-4 flex flex-col  justify-center">
                    <Link to="/auth/sign-in">
                        <button className="welcomeBtn">Sign in</button>
                    </Link>

                    <Link to="/auth/sign-up">
                        <button className="welcomeBtn">Sign up</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
