import React from 'react';
import { Button, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='auth-container'>
            <div className={`h-100`}>
                <div className='container-fluid d-flex flex-column justify-content-center h-100 px-md-4 pb-md-4 pt-md-1'>
                    <div className='container'>
                        <Row align='middle'>
                            <Col xs={24} sm={24} md={8}>
                                <h1 className='mb-4 display-4'>Page not found</h1>
                                <p className='font-size-md mb-4'>
                                    We've noticed you lost your way, no worries, we will help you
                                    to found the correct path.
                                </p>
                                <Link to='/'>
                                    <Button
                                        type='primary'
                                        icon={<ArrowLeftOutlined />}
                                        style={{ width: '150px', marginTop: '10px' }}
                                    >
                                        Go back
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
                                <img
                                    className='img-fluid mt-md-0 mt-4'
                                    src='/img/others/img-20.png'
                                    alt=''
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
