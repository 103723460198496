import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from "axios";
import "../../../src/assets/css/cards.css"
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { API_BASE_URL } from '../../../src/configs/AppConfig';


export default function ResetPass() {
    
    const { token } = useParams();
    const navigate = useNavigate()

    function handleRedirct() {
      navigate('/auth/sign-in');
    }
    const success = () => {
        message.success('Your Password reset is done');
      };
      
  
    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
        Reset password 
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
        Please enter your new password to reset your password
        </p>
        <div>

     <Formik
       initialValues={{ password: ''}}
       validate={values => {
         const errors = {};
         if (!values.password) {
            errors.password = 'Password is required';
        } 
        else if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password required';
          }
          else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Password doesn't not match";
          }
         return errors;
       }}
       onSubmit={(values ,{setSubmitting}) => {
        
       axios.post(`${API_BASE_URL}reset-password/${token}`, {
          password: values.password.trim(),
        })
        .then(function (response) {
          success()
          handleRedirct()        
        })
        .catch(function (error) {
        //   errorM()
          console.log(error);
          setSubmitting(false);
        });
         }
      }
     >
       {({ isSubmitting }) => (
         <Form>
                <Field type="password" placeholder="Password" name="password" id="password" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="password" component="div" id="erroMassage" />
                <Field type="password" placeholder="Confirm password" name="confirmPassword" id="confirmPassword" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="confirmPassword" component="div" id="erroMassage" />                     
           <button type="submit" disabled={isSubmitting} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
           Reset Password
           </button>
         </Form>
       )}
     </Formik>
   </div>
      </div>
    </div>
  );
}
