// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from "axios";
import { Link } from "react-router-dom";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../src/configs/AppConfig';

const errorM = () => {
  message.error('This is email exist before');
};
const success = () => {
  message.success('Your sign up is done');
};


export default function SignUp() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()
  function handleRedirct() {
    navigate('/auth/sign-in');
  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Please fill the form below to sign up!
        </p>
        <div>

          <Formik
            initialValues={{ userName: '', email: '', password: '', company: '', title: '', mobile: '' }}
            validate={values => {
              const errors = {};
              if (!values.userName) {
                errors.userName = 'Full name is required';
              }
              else if (!values.email) {
                errors.email = 'Email is required';
              }
              else if (!values.password) {
                errors.password = 'Password is required';
              }
              else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              else if (!values.confirmPassword) {
                errors.confirmPassword = 'Confirm Password required';
              }
              else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Password doesn't not match";
              }
              else if (!values.company) {
                errors.company = 'Company is required';
              }
              else if (!values.title) {
                errors.title = 'Title is required';
              }
              else if (!values.mobile) {
                errors.mobile = 'Mobile is required';
              }

              return errors;
            }}

            onSubmit={(values, { setSubmitting }) => {
              setIsLoading(true);
              axios.post(`${API_BASE_URL}users`, {
                userName: values.userName.trim(),
                email: values.email.trim(),
                password: values.password.trim(),
                company: values.company.trim(),
                title: values.title.trim(),
                mobile: values.mobile,
              }).then(function (response) {
                success()
                setIsLoading(false);
                handleRedirct()
              }).catch(error => {
                errorM()
                setIsLoading(false);
                console.log("handlesubmit error for blog ", error)

              });


              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
            }
            }
          >
            {({ isSubmitting }) => (
              <Form>
                <Field type="text" placeholder="Full name" name="userName" id="text" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="userName" component="div" id="erroMassage" />
                <Field type="email" placeholder="Email" name="email" id="email" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="email" component="div" id="erroMassage" />
                <Field type="password" placeholder="Password" name="password" id="password" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="password" component="div" id="erroMassage" />
                <Field type="password" placeholder="Confirm password" name="confirmPassword" id="confirmPassword" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="confirmPassword" component="div" id="erroMassage" />
                <Field type="text" placeholder="Company" name="company" id="company" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="company" component="div" id="erroMassage" />
                <Field type="text" placeholder="title" name="title" id="title" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="title" component="div" id="erroMassage" />
                <Field type="tel" placeholder="Mobile" name="mobile" id="mobile" pattern="[0-9]+" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `} />
                <ErrorMessage name="mobile" component="div" id="erroMassage" />


                <button type="submit" disabled={isLoading} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Sign Up
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            You have an account?
          </span>
          <Link to="/auth/sign-in" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">Sign In</Link>
        </div>
      </div>
    </div>
  );
}
