import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from "axios";
import "../../../src/assets/css/cards.css"
import { message } from 'antd';
import { API_BASE_URL } from '../../../src/configs/AppConfig';
import React, {useState } from "react";


export default function ForgetPass() {
  const [isLoading , setIsLoading] = useState(false);

  const success = () => {
    message.success('We send you an email to reset your password');
  };
  const errorM = () => {
    message.error('This is email not exist');
  };
  

    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
        Forget password ?
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
        We'll send you an email with instructions to reset your password
        </p>
        <div>

     <Formik
       initialValues={{ email: ''}}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } 
         else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values ,{setSubmitting}) => {
        setIsLoading(true);
       axios.post(`${API_BASE_URL}forget-password`, {
          email: values.email.trim(),
        })
        .then(function (response) {
          if(response.data){
            success()
            setIsLoading(false)          }
          if(!response.data){
            errorM()
            setIsLoading(false);
          }
      
        })
        .catch(function (error) {
        //   errorM()
          console.log(error);
          setIsLoading(false);
        });
         }
      }
     >
       {({ isSubmitting }) => (
         <Form>
           <Field type="email" placeholder="Email" name="email" id="email" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}/>
           <ErrorMessage name="email" component="div" id="erroMassage" />
          <Link to="/auth/sign-in" className="ml-1 mt-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">Remember my password!</Link> 
                     
           <button type="submit" disabled={isLoading} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
           Send
           </button>
         </Form>
       )}
     </Formik>
   </div>
      </div>
    </div>
  );
}
