export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "Market",
    accessor: "market",
    def: "Targeted countries "
  },
  {
    Header: "Industry",
    accessor: "industry",
    def: "The business activity or the types of products and services a business offers."
  },
  {
    Header: "Platform",
    accessor: "platform",
    def: "Social network used for advertising"
  },
  {
    Header: "Micro-Target",
    accessor: "microTarget",
    def: "Audience behavior"
  },
  {
    Header: "CPM",
    accessor: "CPM",
    def: "(cost per 1,000 impressions) The average cost for 1,000 impressions."
  },
  {
    Header: "LINK-CTR",
    accessor: "CTR",
    def: "The percentage of impressions that received a link click out of the total number of impressions."
  },
  {
    Header: "Ad-Angle",
    accessor: "AdAngle",
    def: "how you deliver your brand or product message"
  },
  {
    Header: "CTR-All",
    accessor: "CTRall",
    def: "The percentage of impressions where a click (all) occurred out of the total number of impressions."
  },
  {
    Header: "Saturation point",
    accessor: "saturationPoint",
    def: "where the impact of a marketing campaign decreases as the target audience is repeatedly exposed to the same message"
  },
  // {
  //   Header: "Action",
  //   accessor: "action",
  // },
];
export const columnsDataCheckUser = [
  {
    Header: "Market",
    accessor: "market",
    def: "Targeted countries "
  },
  {
    Header: "Industry",
    accessor: "industry",
    def: "The business activity or the types of products and services a business offers."
  },
  {
    Header: "Platform",
    accessor: "platform",
    def: "Social network used for advertising"
  },
  {
    Header: "CPM",
    accessor: "CPM",
    def: "(cost per 1,000 impressions) The average cost for 1,000 impressions."
  },
  {
    Header: "LINK-CTR",
    accessor: "CTR",
    def: "The percentage of impressions that received a link click out of the total number of impressions."
  },
  {
    Header: "CTR-All",
    accessor: "CTRall",
    def: "The percentage of impressions where a click (all) occurred out of the total number of impressions."
  },
  {
    Header: "Saturation point",
    accessor: "saturationPoint",
    def: "where the impact of a marketing campaign decreases as the target audience is repeatedly exposed to the same message"
  },
  // {
  //   Header: "Action",
  //   accessor: "action",
  // },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
