// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, {useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import "../../../src/assets/css/cards.css"
import { API_BASE_URL } from '../../../src/configs/AppConfig';


const errorM = () => {
  message.error('Email or Password is wrong');
};

export default function SignIn() {
  
  const [isLoading , setIsLoading] = useState(false);
  const navigate = useNavigate()
  function handleRedirct() {
    navigate('/admin/dashboard');
  }
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div>


     <Formik
       initialValues={{ email: '', password: ''}}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } 
         else if (!values.password) {
          errors.password = 'Required';
        }
         else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values ,{setSubmitting}) => {
        setIsLoading(true);
       axios.post(`${API_BASE_URL}login`, {
          email: values.email.trim(),
          password: values.password.trim()
        })
        .then(function (response) {
          const token = response?.data.access_token
          localStorage.setItem('token', token);
          setIsLoading(false);
          handleRedirct()
          // localStorage.setItem('refreshToken', refreshToken);
          // return response.data.access_token
          
        })
        .catch(function (error) {
          errorM()
          console.log(error);
          setIsLoading(false);
        });
         }
      }
     >
       {({ isSubmitting }) => (
         <Form>
           <Field type="email" placeholder="Email" name="email" id="email" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}/>
           <ErrorMessage name="email" component="div" id="erroMassage" />
           <Field type="password" placeholder="Password" name="password" id="password" className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`} />
           <ErrorMessage name="password" component="div" id="erroMassage" />
          <Link to="/auth/forget-password" className="ml-1 mt-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white ">Forget password ?</Link> 
                     
           <button type="submit" disabled={isLoading} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
           Sign In
           </button>
         </Form>
       )}
     </Formik>
   </div>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}

        {/* Email */}
        
        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
        /> */}

        {/* Password */}
        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
        /> */}
        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button> */}
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link to="/auth/sign-up" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">Create an account</Link>
        </div>
      </div>
    </div>
  );
}
