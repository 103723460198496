const dev = {
    API_ENDPOINT_URL: 'http://localhost:3001/api/',
};

const prod = {
    API_ENDPOINT_URL: 'https://ai.theboosters.net/api/',
};

const test = {
    API_ENDPOINT_URL: 'http://ai.theboosters.net/api-staging/',
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev;
        case 'production':
            return prod;
        case 'test':
            return test;
        default:
            break;
    }
};

export const env = getEnv();
