export const requestsData = [
    {
        Header: "User name",
        accessor: "userName",
    },
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: "Mobile",
        accessor: "mobile",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Date",
        accessor: "time",
    },
];
