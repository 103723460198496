import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import RTLDefault from "views/rtl/default";
import Requests from "views/admin/default/components/UesersRequests"
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/signUp";

// Icon Imports
import {
  MdHome,
  // MdOutlineShoppingCart,
  // MdBarChart,
  MdPerson,
  MdLock,
  MdContactSupport,
} from "react-icons/md";
import WelcomeAuth from "views/auth/WelcomeAuth";
import ForgetPass from "views/auth/ForgetPass";
import ResetPass from "views/auth/ResetPass";
import NotFound from "views/auth/NotFound";



const routes = [
  {
    name: "Industry Benchmark",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    role: "User",
  },
  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "Users",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  {
    name: "Users",
    layout: "/admin",
    icon: <MdPerson className="h-6 w-6" />,
    path: "users",
    component: <DataTables />,
    role: "Admin",
  },
  {
    name: "Requests",
    layout: "/admin",
    path: "requests",
    icon: <MdContactSupport className="h-6 w-6" />,
    component: <Requests />,
    role: "Admin",
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    role: "User",
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
    role: "User",

  },
  {
    name: "Welcome",
    layout: "/auth",
    path: "welcome",
    icon: <MdLock className="h-6 w-6" />,
    component: <WelcomeAuth />,
    role: "User",
  },
  {
    name: "Forget Password",
    layout: "/auth",
    path: "forget-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPass />,
    role: "User",

  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: `reset-password/:token`,
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPass />,
    role: "User",
  },
  {
    name: "Not Found",
    layout: "/auth",
    path: `not-found`,
    icon: <MdLock className="h-6 w-6" />,
    component: <NotFound />,
    role: "User",
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
