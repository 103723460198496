import { React } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { lazy } from "react";



const AdminLayout = lazy(
  () => import("layouts/admin")
);


function PrivateRoute({ children }) {
  const auth = localStorage.getItem('token')
  return auth ? children : <Navigate to="/auth/sign-in" />;
}

const App = () => {
  
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <AdminLayout/>
            </PrivateRoute>
          }
        />
      {/* <Route path="admin/*" element={<AdminLayout />} /> */}
      {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
      <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
      <Route path="/*" element={<Navigate to="/auth/not-found" replace />} />

    </Routes>

  //   <Routes>
  //   <Route path="auth/sign-in" element={<AuthLayout />} />
  //   <Route path="/admin" element={<PrivateOutlet />}>
  //     <Route path="" element={<AdminLayout />} />
  //   </Route>
  //   <Route
  //         path="/admin"
  //         element={
  //           <PrivateRoute>
  //             <AdminLayout />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route path="/auth/sign-in" element={<SignIn />} />
  // </Routes>

  );
};

export default App;
